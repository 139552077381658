"use strict";

(function () {
  var genderIconClassesInfo = {
    "default": {
      icon: 'icon icon-manalt'
    },
    'Homem': {
      icon: 'icon icon-manalt'
    },
    'Mulher': {
      icon: 'icon icon-womanalt'
    },
    'Organização': {
      icon: 'icon icon-office-building'
    },
    'Marca': {
      icon: 'icon icon-office-building'
    }
  };
  var labels = {
    man: 'Homem',
    woman: 'Mulher',
    organization: 'Organização'
  };
  var man = {
    iconClasses: genderIconClassesInfo[labels.man].icon,
    mainIconSuffix: 'manalt',
    value: labels.man,
    title: labels.man
  };
  var woman = {
    iconClasses: genderIconClassesInfo[labels.woman].icon,
    mainIconSuffix: 'womanalt',
    value: labels.woman,
    title: labels.woman
  };
  var organization = {
    iconClasses: genderIconClassesInfo[labels.organization].icon,
    mainIconSuffix: 'office-building',
    value: labels.organization,
    title: labels.organization
  };

  // Nowadays, these values are considered equivalent
  var equivalentValues = {
    'Organização': 'Marca',
    'Marca': 'Organização'
  };
  window.GendersInfo = {
    genders: {
      "default": man,
      man: man,
      woman: woman,
      organization: organization
    },
    iconClasses: genderIconClassesInfo,
    equivalentValues: equivalentValues
  };
})();